// import lunr from 'lunr'
import lunr from 'lunr'
import * as Sentry from '@sentry/vue'
import type {
  ICMSClosedClaimsTable,
  IShareKeyData,
  ITable,
  ITableField,
  IUserData,
} from './types/schema'
import type { ISearchData } from './types/app'
import SchemaService from '~/services/schema-service'

export const useSchemaStore = defineStore('schemaStore', () => {
  const schemaService = new SchemaService()

  // States
  const userData: Ref<IUserData | null> = ref(null)
  const userAuthenticated: Ref<Boolean> = ref(false)
  const schema: Ref<any> = ref(null)
  const validsharekey: Ref<string> = ref('')
  // Using any as lunr.Index can't be typed
  const searchTableLookup: Ref<any> = ref(null)
  // TODO: remove this. not in use
  const changelog: Ref<any> = ref(null)
  const schemaComingSoon: Ref<[] | null> = ref(null)
  const activeTable: Ref<ITable | null> = ref(null)
  const activeField: Ref<ITableField | null> = ref(null)
  const activeschema: Ref<string | null> = ref(null)
  const shareKeyData: Ref<IShareKeyData | null> = ref(null)
  const copiedCode: Ref<string> = ref('')

  // Adding an order is not necessary, ones without order will be added in the end
  const groupOrder: string[] = [
    'PATIENT READYDATA',
    'PROVIDER 360 READYDATA',
    'REFERENCE',
  ]
  const LoadingMessage: Ref<string> = ref(
    'Please wait while we authenticate you',
  )
  const subGroupOrder: Record<string, string[]> = {
    'PATIENT READYDATA': [
      'OPEN CLAIMS READYDATA',
      'MULTI-PAYER CLOSED CLAIMS READYDATA',
      'ALL MEDICARE CLOSED CLAIMS READYDATA',
      'ELECTRONIC HEALTH RECORDS',
      'OTHER',
    ],
    'PROVIDER 360 READYDATA': [
      'AFFILIATIONS',
      'ACCOUNT',
      'HCP',
      'CLINICAL TRIALS',
      'PUBLICATION',
      'OPEN PAYMENTS',
    ],
    REFERENCE: ['ENTITY', 'CROSSWALK', 'OTHER'],
  }

  // DD PDv3 properties
  const schemaV3: Ref<any> = ref(null)
  const activeProduct: Ref<string | null> = ref(null)
  const searchTableLookupV3: Ref<any> = ref(null)
  const defaultProductsOrder: string[] = [
    'PATIENT READYDATA',
    'PROVIDER 360 READYDATA',
  ]
  const defaultDatabaseOrderV3: Record<string, string[]> = {
    'PATIENT READYDATA': [
      'COMPILE_CLAIMS',
      'COMPILE_EHR',
      'ALL_MEDICARE_CLOSED_CLAIMS_READYDATA',
      'COMPILE_REFERENCES',
      'COMPILE_ADD_ON',
    ],
    'PROVIDER 360 READYDATA': ['COMPILE_PROVIDER_360', 'COMPILE_ADD_ON'],
  }
  const nodeOnlyTablesHashKeys: Record<string, string> = {
    ALL_MEDICARE_CLOSED_CLAIMS_READYDATA:
      'CMS.ALL_MEDICARE_CLOSED_CLAIMS_READYDATA',
  }
  // End of DD PDv3 properties

  const subgroupDescriptions: Record<string, string> = {
    PRIME_PLD:
      "McKesson Compile's enhanced Anonymous Patient Level Data (abbreviated as APLD or just PLD) is claims layout with integrated affiliations and smart cleanups to power your analytical projects",
  }
  const cmsClosedClaimsTable: ICMSClosedClaimsTable = {
    name: 'ALL MEDICARE CLOSED CLAIMS READYDATA',
    label: 'ALL_MEDICARE_CLOSED_CLAIMS_READYDATA',
    fields: [],
    is_view: false,
    relations: [],
    description:
      'Aggregated data summaries of 100% closed Medicare claims from Centers for Medicare and Medicaid Services (CMS). Transactional data is not available. Data must be summarized to the patient group of 10 or more.',
    table_count: 0,
    patients_count: '68M',
    last_updated: '',
    database_name: '',
    hashPath: 'CMS.ALL_MEDICARE_CLOSED_CLAIMS_READYDATA',
    isLastNode: true,
    source_update_frequency: '',
    sfSchemaName: 'CMS',
  }
  const nodeOnlyTables: string[] = ['ALL MEDICARE CLOSED CLAIMS READYDATA']

  // Mutations

  const setUser = (payload: IUserData) => {
    userData.value = payload
  }

  const isUserAuthenticated = (payload: boolean) => {
    userAuthenticated.value = payload
  }

  const setValidShareKey = (payload: string) => {
    validsharekey.value = payload
  }

  const clearActives = () => {
    activeProduct.value = null
    activeschema.value = null
    activeTable.value = null
    activeField.value = null
  }

  const setActives = (tableID: string) => {
    setDDSchemaActives(tableID, schema.value, 3)
  }

  const setActiveSchema = (schemaValue: string) => {
    activeschema.value = schemaValue
    // TODO: Replace type any if possible
    const entries: any[] = Object.entries(
      schema.value.tablegrouping[schemaValue],
    )
    activeTable.value = entries[0][1][0]

    updateRouteHash(activeTable.value as ITable, 'v2')
  }

  const setActiveField = (field: ITableField | null) => {
    activeField.value = field
  }

  const setSchemaMeta = (schemaMeta: any) => {
    const computedSchema = {
      tablelookup: null,
      tablegrouping: null,
      groupLookup: null,
      fieldlookup: null,
      schema_json: schemaMeta.schema,
      created: new Date(schemaMeta.created_at.split(' ')[0]),
    }
    schema.value = computedSchema

    const schemaJson = schema.value.schema_json
    const x = Object.assign({}, schemaJson)
    x.schema = schemaJson

    setSchema(schemaMeta.schema)
  }

  const setSchema = (schemaData: any) => {
    const computedSchema: any = Object.assign({}, schema)
    computedSchema.tablelookup = {}
    computedSchema.tablegrouping = {}
    computedSchema.groupLookup = {}
    computedSchema.fieldlookup = {}
    const searchData: { id: string; description: any; label: any }[] = []
    const actualGroups = Object.keys(schemaData.schemas)
    const customOrder = groupOrder.filter(
      (v: string) => v in schemaData.schemas,
    )
    const difference = actualGroups.filter((x) => !customOrder.includes(x))
    customOrder
      .concat(difference)
      .forEach((groupName: string, orderIndex: number) => {
        const groupData = schemaData.schemas[groupName]
        // Adding All Medicare Closed Claims ReadyData under PATIENT READYDATA--> Others.
        // JIRA: CONS-5
        if (groupName === 'PATIENT READYDATA') {
          groupData['ALL MEDICARE CLOSED CLAIMS READYDATA'] = {
            CMS: {
              ALL_MEDICARE_CLOSED_CLAIMS_READYDATA: cmsClosedClaimsTable,
            },
          }
        }
        computedSchema.tablegrouping[groupName] = {}
        const actualSubGroups = Object.keys(groupData)
        const customSubGroupOrder = subGroupOrder[groupName].filter(
          (v: string) => v in groupData,
        )
        const difference = actualSubGroups.filter(
          (x) => !customSubGroupOrder.includes(x),
        )
        customSubGroupOrder
          .concat(difference)
          .forEach((subGroupName: string | number) => {
            const subGroupData = groupData[subGroupName]
            Object.entries(subGroupData).forEach((sfSchema) => {
              const [sfSchemaName, tables] = sfSchema
              Object.entries(tables).forEach((table) => {
                const tableData: ITable = table[1] as ITable
                // converting table and field descriptions to markdown
                if (
                  Object.prototype.hasOwnProperty.call(tableData, 'description')
                ) {
                  // eslint-disable-next-line no-self-assign
                  tableData.description = tableData.description
                }
                tableData.sfSchemaName = sfSchemaName
                tableData.is_view = false
                // table_data.hasOwnProperty('is_view') && table_data.is_view === true
                tableData.fields.forEach(
                  (field: {
                    hasOwnProperty: (arg0: string) => any
                    description: string | null
                    name: string
                  }) => {
                    if (
                      Object.prototype.hasOwnProperty.call(
                        field,
                        'description',
                      ) &&
                      field.description !== '' &&
                      field.description !== null
                    ) {
                      // eslint-disable-next-line no-self-assign
                      field.description = field.description
                    }
                    computedSchema.fieldlookup[
                      sfSchemaName + '.' + tableData.label + '.' + field.name
                    ] = field
                  },
                )
                try {
                  computedSchema.tablegrouping[groupName][subGroupName].push(
                    tableData,
                  )
                } catch (err) {
                  computedSchema.tablegrouping[groupName][subGroupName] = [
                    tableData,
                  ]
                }
                computedSchema.tablelookup[
                  sfSchemaName + '.' + tableData.label
                ] = [tableData, orderIndex]
                computedSchema.groupLookup[
                  sfSchemaName + '.' + tableData.label
                ] = groupName

                // for schema wide search
                const searchDataobj = {
                  id: sfSchemaName + '.' + tableData.label,
                  description: tableData.description,
                  label: tableData.label,
                }
                searchData.push(searchDataobj)

                tableData.fields.forEach(
                  (field: { name: string; description: any }) => {
                    const searchDataobj = {
                      id:
                        sfSchemaName + '.' + tableData.label + '.' + field.name,
                      description: field.description,
                      label: field.name,
                    }
                    searchData.push(searchDataobj)
                  },
                )
              })
            })
          })
      })

    schema.value.tablelookup = computedSchema.tablelookup
    schema.value.tablegrouping = computedSchema.tablegrouping
    schema.value.groupLookup = computedSchema.groupLookup
    schema.value.fieldlookup = computedSchema.fieldlookup

    // lunr search data indexing
    searchTableLookup.value = lunrIndexing(searchData)
  }

  // Data dictionary version 3
  const setSchemaV3Meta = (schemaMeta: any) => {
    const computedSchema = {
      products: null,
      groupLookup: null,
      tablelookup: null,
      fieldlookup: null,
      schema_json: schemaMeta.schema,
      created: new Date(schemaMeta.created_at.split(' ')[0]),
    }
    schemaV3.value = computedSchema
    setSchemaV3(schemaMeta.schema)
  }

  const setSchemaV3 = (schemaData: any) => {
    const computedSchemaV3: Record<string, any> = {
      products: {},
      groupLookup: {},
      tablelookup: {},
      fieldlookup: {},
    }
    const searchData: ISearchData[] = []
    const schemaV3Json = { ...schemaData.schemas }
    // Group the products from schema json data
    const actualProducts = Object.keys(schemaData.schemas)
    // Sort/Order the products as per the default order
    const products = [
      ...defaultProductsOrder.filter((item) => actualProducts.includes(item)),
      ...actualProducts.filter((item) => !defaultProductsOrder.includes(item)),
    ]

    // Products - Tab level
    products.forEach((productName: string) => {
      const productData = schemaV3Json[productName]
      // Adding All Medicare Closed Claims ReadyData under PATIENT READYDATA--> Others.
      if (productName === 'PATIENT READYDATA') {
        productData.ALL_MEDICARE_CLOSED_CLAIMS_READYDATA = {
          CMS: {
            ALL_MEDICARE_CLOSED_CLAIMS_READYDATA: cmsClosedClaimsTable,
          },
        }
      }
      // Group the database from schema json data
      const actualDatabaseGroups = Object.keys(productData)
      // Sort/Order the database as per the default order
      const databaseGroups = [
        ...defaultDatabaseOrderV3[productName].filter((item) =>
          actualDatabaseGroups.includes(item),
        ),
        ...actualDatabaseGroups.filter(
          (item) => !defaultDatabaseOrderV3[productName].includes(item),
        ),
      ]
      computedSchemaV3.products[productName] = {}
      // Database - Hierarchy level 1
      databaseGroups.forEach((databaseName: string) => {
        const databaseData = productData[databaseName]
        const schemaGroups = Object.keys(databaseData)
        computedSchemaV3.products[productName][databaseName] = {}
        // Schema - Hierarchy level 2
        schemaGroups.forEach((schemaName: string) => {
          const schemaV3Data = databaseData[schemaName]
          const tables = Object.keys(schemaV3Data)
          computedSchemaV3.products[productName][databaseName][schemaName] = {}
          // Tables - Hierarchy level 3
          tables.forEach((tableName: string) => {
            const tableData = schemaV3Data[tableName]
            tableData.isLastNode = true
            const hashPathPrefix = nodeOnlyTables.includes(
              databaseName.replaceAll('_', ' '),
            )
              ? `${schemaName}.${tableName}`
              : `${databaseName}.${schemaName}.${tableName}`

            // Generate the hash path/ table full name
            tableData.hashPath = `#${hashPathPrefix}`
            computedSchemaV3.products[productName][databaseName][schemaName][
              tableName
            ] = tableData
            computedSchemaV3.tablelookup[hashPathPrefix] = [tableData]
            computedSchemaV3.groupLookup[hashPathPrefix] = productName

            // Tables search data
            searchData.push({
              id: hashPathPrefix,
              description: tableData.description,
              label: tableData.label,
            })

            tableData.fields.forEach(
              (field: { name: string; description: any }) => {
                const id = `${databaseName}.${schemaName}.${tableName}.${field.name}`
                // Fields search data
                searchData.push({
                  id,
                  description: field.description,
                  label: field.name,
                })
                computedSchemaV3.fieldlookup[id] = field
              },
            )
          })
        })
      })
    })

    schemaV3.value.products = computedSchemaV3.products
    schemaV3.value.tablelookup = computedSchemaV3.tablelookup
    schemaV3.value.groupLookup = computedSchemaV3.groupLookup
    schemaV3.value.fieldlookup = computedSchemaV3.fieldlookup

    // lunr search data indexing
    searchTableLookupV3.value = lunrIndexing(searchData)
  }

  const setActiveProduct = (productName: string) => {
    activeProduct.value = productName
    const database: [string, Object][] = Object.entries(
      schemaV3.value.products[productName],
    )
    const arrschema: [string, Object][] = Object.entries(database[0][1])
    const arrtables: [string, ITable][] = Object.entries(arrschema[0][1])
    activeTable.value = arrtables[0][1]

    updateRouteHash(activeTable.value, 'v3')
  }

  const setActivesV3 = (tableID: string) => {
    setDDSchemaActives(tableID, schemaV3.value, 4)
  }
  // Data dictionary v3 end

  // Reusable method in DD v2 and v3 to update the route path with hash
  const updateRouteHash = (table: ITable, version: string) => {
    const currentPath = window.location.href.replace(window.location.origin, '')
    let hash: string = ''
    let newRoute: string = ''
    if (version === 'v2') {
      hash = '#' + table?.sfSchemaName + '.' + activeTable?.value?.label
    } else {
      hash = table?.hashPath?.replaceAll(' ', '_') || ''
    }

    if (window.location.hash) {
      newRoute = currentPath.substring(0, currentPath.indexOf('#')) + hash
    } else {
      newRoute = currentPath + hash
    }
    history.pushState({}, '', newRoute)
  }

  const lunrIndexing = (searchData: ISearchData[]) => {
    return lunr(function (this: any) {
      this.ref('id')
      this.field('description')
      this.field('label')
      // eslint-disable-next-line import/no-named-as-default-member
      this.pipeline.remove(lunr.stemmer)

      searchData.forEach(function (this: any, doc) {
        this.add(doc)
      }, this)
    })
  }

  const setDDSchemaActives = (tableID: string, schema: any, index: number) => {
    if (tableID) {
      tableID = tableID.replace('#', '')
      if (tableID.split('.').length === index) {
        activeField.value = schema.fieldlookup[tableID]
        tableID = tableID
          .split('.')
          .slice(0, index - 1)
          .join('.')
      } else {
        activeField.value = null
      }
      const schemaActive = schema.groupLookup[tableID]
      if (schemaActive) {
        activeTable.value = schema.tablelookup[tableID][0]
        activeProduct.value = schemaActive
      } else {
        // Fall back to the first product/database/schema/table on wrong table name in the url
        const firstProduct = Object.keys(schema.products)[0]
        setActiveProduct(firstProduct)
      }
    }
  }

  const setChangelog = (value: any) => {
    changelog.value = value
  }

  const setComingSoonSchema = (payload: any) => {
    schemaComingSoon.value = payload
  }
  const setLoadingMessage = (payload: any) => {
    LoadingMessage.value = payload
  }
  const setCopiedCode = (payload: string) => {
    copiedCode.value = payload
  }
  const setShareKeyData = (payload: IShareKeyData) => {
    shareKeyData.value = payload
  }
  // Actions
  const fetchSchemaData = async (version: string) => {
    const route = useRoute()
    if (
      (version === 'v2' && schema.value === null) ||
      (version === 'v3' && schemaV3.value === null)
    ) {
      await schemaService.fetchSchema(version).then(
        (response: any) => {
          if (response && response.user) {
            if (response.user === 'ShareKey') {
              isUserAuthenticated(true)
              setValidShareKey(route.query.s as string)
            } else {
              let name = response.user.name
              name =
                name !== null && name.length > 0 ? name : response.user.username
              const userObject = Object.assign({}, response.user, { name })
              setUser(userObject)
              isUserAuthenticated(true)
              Sentry.setUser({ email: userObject.email })
              Sentry.setContext('userinfo', {
                hashid: userObject.hashid,
              })
            }
            if (version === 'v2') {
              setSchemaMeta(response)
            } else {
              setSchemaV3Meta(response)
            }
          }
        },
        (_err: Error) => {
          // Error message on invalid share key.
          if (route.query.s) {
            const errorMessage =
              'The link has expired. Please contact your sales representative or email  <a href="mailto:compile_sales@mckesson.com" class="alert-link">compile_sales@mckesson.com</a> for a new link.'
            throw createError({
              statusCode: 500,
              statusMessage: errorMessage,
              fatal: true,
            })
          }
        },
      )
    }
  }

  const generateShareUrl = async (version: string) => {
    await schemaService
      .generateShareUrl(version)
      .then((response: IShareKeyData) => {
        setShareKeyData(response)
      })
  }

  return {
    userData,
    schema,
    schemaV3,
    schemaComingSoon,
    userAuthenticated,
    activeschema,
    activeProduct,
    activeTable,
    activeField,
    nodeOnlyTables,
    subgroupDescriptions,
    copiedCode,
    shareKeyData,
    validsharekey,
    searchTableLookup,
    nodeOnlyTablesHashKeys,
    searchTableLookupV3,
    setUser,
    isUserAuthenticated,
    clearActives,
    setActives,
    setActiveSchema,
    setActiveProduct,
    setActiveField,
    setSchemaMeta,
    setSchemaV3Meta,
    setSchema,
    setChangelog,
    setComingSoonSchema,
    setLoadingMessage,
    setCopiedCode,
    fetchSchemaData,
    generateShareUrl,
    setActivesV3,
  }
})
